.bytemd {
    height: calc(100vh - 350px);
}

/*.ck-editor__editable[role="textbox"] {*/
/*    !* editing area *!*/
/*    height: 100%;*/

/*}*/
.tiptap .memu_bar {
    background: red;
}

.ck-content .image {
    /* block images */
    max-width: 80%;
    margin: 20px auto;
}

.NodeAccordion.MuiPaper-elevation {
    box-shadow: none !important;
    background-color: #f4f6f9;
    border-radius: 8px !important;
    overflow: hidden !important;

    &.MuiExpansionPanel-root:before {
        display: none !important;
    }
}