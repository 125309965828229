.ApiListItemButton .ApiListItemActionButton {
    visibility: hidden;
}

.ApiListItemButton:hover .ApiListItemActionButton {
    visibility: visible;
}

.ApiListItem .ApiListItemAction {
    visibility: hidden;
}

.ApiListItem:hover .ApiListItemAction {
    visibility: visible;
}
::-webkit-scrollbar {
    display: none; /* 对于 Chrome, Safari 和 Opera */
}

/* 对于 IE, Edge 和 Firefox */
* {
    -ms-overflow-style: none;  /* 对于 IE 和 Edge */
    scrollbar-width: none;  /* 对于 Firefox */
}
